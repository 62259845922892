export const photos = [
  {
    src: "/images/galeria/project1.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "/images/galeria/project2.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "/images/galeria/project3.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "/images/galeria/project4.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "/images/galeria/project5.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "/images/galeria/project6.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "/images/galeria/project7.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "/images/galeria/project8.jpg",
    width: 4,
    height: 3,
  },
];
