import React, { Component } from "react";
export default class ContactUs extends Component {
  render() {
    //let resumeData = this.props.resumeData;
    return (
      <section id="contact">
        <div className="row section-head">
          <h1 className="lead">Neváhajte nás kontaktovať</h1>
        </div>
        <div className="row">
          <div className="columns contact-details">
            <h2>Kontaktné údaje</h2>
            <p>Ing. Igor Zacher - ZA PBS s.r.o.</p>
            <p>
              Telefón: <a href="tel:+421 944 772 850">+421 944 772 850</a>
            </p>
            <p>
              E-mail:
              <a href="mailto:igorzacher90@gmail.com">igorzacher90@gmail.com</a>
            </p>
            <p>
              WEB:
              <a href="www.zapbs.sk" target="_blank">
                www.zapbs.sk
              </a>
            </p>

            {/* <p className="address">
              <span>{resumeData.name}</span>
              <br />
              <span>{resumeData.address}</span>
            </p> */}
          </div>
          <div className="columns contact-details">
            <h2>Fakturačné údaje</h2>
            <p>ZA PBS s.r.o.</p>
            <p>Ústecko-Orlická 3300/25, 05801 Poprad</p>
            <p>IČO 55859755</p>
            <p>DIČ 2122107746</p>
            {/* <p>IBAN: SK85 8330 0000 0028 0233 2540</p> */}
            {/* Firma je zapísaná v Obchodnom registri Okresného súdu Žilina,
            oddiel: Sro, vložka č. 80494/L */}
            {/* <p className="address">
              <span>{resumeData.name}</span>
              <br></br>
              <span>{resumeData.address}</span>
            </p> */}
          </div>
        </div>
        {/* <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                <h4>Linked in :
                  {resumeData.linkedinId}
                </h4>
              </div>
            </aside>
          </div> */}
      </section>
    );
  }
}
