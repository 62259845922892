import React, { Component } from "react";
export default class Footer extends Component {
  render() {
    //let resumeData = this.props.resumeData;
    return (
      <footer>
        <span>
          Copyright © 2023, Ing. Igor Zacher. Všetky práva vyhradené. Vytvorila
        </span>
        <a
          href="https://www.linkedin.com/in/maria-nemcova-852519193/"
          target="_blank"
          rel="noreferrer"
        >
          Maria Nemcova
        </a>
        {/* <div className="row">
          <div className="twelve columns">
            <ul className="social-links">
              {resumeData.socialLinks &&
                resumeData.socialLinks.map((item, number) => {
                  return (
                    <li key={number.toString()}>
                      <a href={item.url}>
                        <i className={item.className} />
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div> */}
        <div id="go-top">
          <a className="smoothscroll" title="Back to Top" href="#home">
            <i className="icon-up-open" />
          </a>
        </div>
      </footer>
    );
  }
}
