let resumeData = {
  imagebaseurl: "",
  name: "ZA PBS",
  role: "SZČO",
  linkedinId: "Your LinkedIn Id",
  skypeid: "Your skypeid",
  cenovaPonuka:
    "Každá práca s klientom si vyžaduje osobitný prístup. To je hlavný dôvod, prečo nie je fixný cenník. Rád vám však vypracujem cenovú ponuku, ktorá je ZDARMA. Stačí odoslať email a ja vás kontaktujem. Vopred ďakujem za prejavenú dôveru.",
  roleDescription: "Požiarno Bezpečnostné Služby",
  roleDescription2: "Protipožiarna Bezpečnosť Stavieb",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com",
      className: "fa fa-linkedin",
    },
    {
      name: "facebook",
      url: "http://facebook.com",
      className: "fa fa-facebook",
    },
  ],
  aboutme:
    "Volám sa Ing. Igor Zacher a zaoberám sa poskytovaním služieb v oblasti BOZP a požiarnej ochrany v rámci celého Slovenska i v zahraničí.",
  resum:
    "Bezpečnosti pri práci, požiarnej ochrane a protipožiarnej bezpečnosti stavieb sa venujem už viac ako 8 rokov. Za toto obdobie som vyškolil už vyše 1000 zamestnancov, zabezpečoval som požiadavky BOZP a požiarnej ochrany pre vyše 100 firiem a  vypracoval som vyše 200 projektov protipožiarnej bezpečnosti pre rôzne stavby od rodinných domov, cez bytové domy, školy, škôlky, zdravotnícke zariadenia, až po výrobné stavby a sklady.",
  resum2:
    "Sídlim v Žiline, ale pre klientov sa nebojím prekonávať aj väčšie vzdialenosti. Pracujem na rôznych projektoch po celom Slovensku, no venujem sa aj klientom v zahraničí.",
  resum3:
    "Naša spolupráca s vykonaním školenia či kontroly nekončí.  Poskytujem odborný servis, poradenstvo i pravidelné kontroly. Vyhlášky a nariadenia vysvetlím ľudskou rečou. Nebojím sa hľadať riešenia a klientom poskytujem služby vždy na mieru podľa ich potrieb  Dokumentáciu vypracujem vždy v čo najkratšom možnom čase. Neustále hľadám efektívne riešenia, tak, aby boli splnené všetky právne predpisy a dodržaná bezpečnosť, aj spokojnosť klienta.",
  address: "Slovensko",
  website: "www.zapbs.sk",
  portfolio: [
    // {
    //   name: "",
    //   description:
    //     "Jednou z hlavných špecializácií firmy Vertical Office sú práve telekomunikačné zariadenia a systémy, v ktorých máme bohaté skúsenosti a široký záber. Počnúc výstavbou nosných konštrukcií, cez inštalácie nových site-ov, pridávanie nových technológií či upgrady existujúcich technológií.",
    // },
    // {
    //   name: "Fotovoltaické systémy a zelená energia",
    //   description:
    //     "Inštalácia solárnych panelov a fotovoltaických systémov, navrhnutie zariadení na mieru. Solárne systémy sú navrhnuté pre optimálne využitie solárnej energie a tým úsporu celkových nákladov.",
    // },
    {
      name: "Technik požiarnej ochrany",
      list: [
        "Vykonávanie preventívnych protipožiarnych prehliadok",
        "Určovanie miest so zvýšeným nebezpečenstvom vzniku požiaru a počtu členov protipožiarnych hliadok",
        "Vykonávanie pravidelného školenia o ochrane pred požiarmi a odbornej prípravy protipožiarnych hliadok",
        "Vypracovanie, vedenie a udržiavanie dokumentácie ochrany pred požiarmi v súlade so skutočným stavom",
      ],
    },
    {
      name: "Špecialista požiarnej ochrany",
      list: [
        "Vypracovanie projektu protipožiarnej bezpečnosti stavby pre novostavby, rodinné domy, zmeny stavieb",
      ],
    },
    // {
    //   name: " Čistiace práce a umývanie",
    //   list: [
    //     "Čistenie okien a sklenených plôch",
    //     "Čistenie fasád a striech budov",
    //     "Čistenie technologických zariadení",
    //     "Čistenie stropných konštrukcií",
    //     "Čistenie síl a zásobníkov",
    //   ],
    // },
    // {
    //   name: "Iné práce",
    //   list: [
    //     "Arboristika, rizikové spiľovanie stromov",
    //     "Sanácie svahov a odstraňovanie náletových drevín",
    //     "Montáž sietí a hrotov proti vtákom či hmyzu ",
    //     "Reklamné práce, vytváranie audiovizuálneho materiálu",
    //     "Ohliadky, revízie komínov, kontroly a iné",
    //     "Montáž a demontáž reklám, billboardov, osvetlení a pod",
    //     "Výstavba lanových dráh, zážitkových chodníkov a ferát",
    //     "Výstavba zabezpečovacích zariadení",
    //   ],
    // },
  ],
  // work: [
  //   {
  //     CompanyName: "Some Company",
  //     specialization: "Some specialization",
  //     MonthOfLeaving: "Jan",
  //     YearOfLeaving: "2018",
  //     Achievements: "Some Achievements",
  //   },
  //   {
  //     CompanyName: "Some Company",
  //     specialization: "Some specialization",
  //     MonthOfLeaving: "Jan",
  //     YearOfLeaving: "2018",
  //     Achievements: "Some Achievements",
  //   },
  // ],
  // skillsDescription: "Your skills here",
  // skills: [
  //   {
  //     skillname: "HTML5",
  //   },
  //   {
  //     skillname: "CSS",
  //   },
  //   {
  //     skillname: "Reactjs",
  //   },
  // ],
  galery: [
    {
      name: "project1",
      description: "opis",
      imgurl: "images/galeria/project1.jpg",
    },
    {
      name: "project2",
      description: "opis",
      imgurl: "images/galeria/project2.jpg",
    },
    {
      name: "project3",
      description: "opis",
      imgurl: "images/galeria/project3.jpg",
    },
    {
      name: "project4",
      description: "opis",
      imgurl: "images/galeria/project4.jpg",
    },
    {
      name: "project5",
      description: "opis",
      imgurl: "images/galeria/project5.jpg",
    },
    {
      name: "project6",
      description: "opis",
      imgurl: "images/galeria/project6.jpg",
    },
    {
      name: "project7",
      description: "opis",
      imgurl: "images/galeria/project7.jpg",
    },
    {
      name: "project8",
      description: "opis",
      imgurl: "images/galeria/project8.jpg",
    },
  ],
  testimonials: [
    {
      description: "Rýchlo a spoľahlivo",
      name: "",
    },
    {
      description: "Detailná dokumentácia",
      name: "",
    },
    {
      description: "Rešpektujem priania klientov",
      name: "",
    },
  ],
  skusenosti: [
    {
      name: "Slovnaft",
      imgurl: "images/skusenosti/slovnaft.png",
      link: "",
    },
    {
      name: "SHR",
      imgurl: "images/skusenosti/shr.png",
      link: "https://www.shr.sk/",
    },
    {
      name: "Drevomax",
      imgurl: "images/skusenosti/drevomax.png",
      link: "",
    },
    {
      name: "Dubai Industry",
      imgurl: "images/skusenosti/dubai.png",
      link: "",
    },
    {
      name: "Metsä",
      imgurl: "images/skusenosti/metsä.png",
      link: "",
    },
    {
      name: "Elektrovod",
      imgurl: "images/skusenosti/elektrovod.png",
      link: "",
    },
  ],
  sluzby: [
    {
      name: "BOZP: Bezpečnosť a ochrana zdravia pri práci",
      description: "Komplexné služby bezpečnostného technika",
      imgurl: "images/sluzby/bozp.svg",
      link: "",
    },
    {
      name: "OPP: Ochrana pred požiarmi",
      description:
        "Technik požiarnej ochrany, školenia, kontroly a dokumentácia OPP",
      imgurl: "images/sluzby/opp.svg",
      link: "",
    },
    {
      name: "Výchova a vzdelávanie",
      description: "Oboznamovanie a informovanie BOZP a školenia OPP",
      imgurl: "images/sluzby/vychova.svg",
      link: "",
    },
    {
      name: "Protipožiarna bezpečnosť stavieb",
      description:
        "Vypracovanie projektovej dokumentácie PBS pre všetky druhy stavieb",
      imgurl: "images/sluzby/koordinacia.svg",
      link: "",
    },
    {
      name: "Kontrola a servis požiarnych zariadení",
      description:
        "Pravidelné kontroly a opravy hasiacich prístrojov, hydrantov a iného vybavenia.",
      imgurl: "images/sluzby/kontrola.svg",
      link: "",
    },
  ],
};

export default resumeData;
