import React, { Component } from "react";
export default class Skusenosti extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="skusenosti">
        <div className="row section-head">
          <div className="ten columns">
            <h1>
              <span>Skúsenosti</span>
            </h1>
          </div>
          <div className="skusenosti">
            {resumeData.skusenosti &&
              resumeData.skusenosti.map((item, number) => {
                return (
                  <div key={number.toString()}>
                    <img src={item.imgurl} alt="" />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    );
  }
}
