import React, { Component } from "react";
import { Carousel } from "@trendyol-js/react-carousel";
// import { Item } from "./yourItem";
export default class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="resume">
        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Služby</span>
            </h1>
          </div>
          <Carousel className="carousel" show={2.5} slide={2} swiping={true}>
            {resumeData.sluzby &&
              resumeData.sluzby.map((item, number) => {
                return (
                  <div
                    id={number.toString()}
                    className="item"
                    key={number.toString()}
                  >
                    <h3>{item.name}</h3>
                    <img src={item.imgurl} alt=""></img>
                    <p>{item.description}</p>
                    {/* <a href={item.link}>Zistite viac</a> */}
                  </div>
                );
              })}
          </Carousel>

          {/* <div className="nine columns main-col">
            {resumeData.portfolio &&
              resumeData.portfolio.map((item, number) => {
                return (
                  <div className="row item" key={number.toString()}>
                    <div className="twelve columns">
                      <h3>{item.name}</h3>
                      <dl>
                        {item.list &&
                          item.list.map((desc, number) => {
                            return <li key={number.toString()}>{desc}</li>;
                          })}
                      </dl>

                      <p>{item.description}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div> */}
          {/* <div className="row work">
          <div className="three columns header-col">
            <h1>
              <span>Work</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            {resumeData.work &&
              resumeData.work.map((item) => {
                return (
                  <div className="row item">
                    <div className="twelve columns">
                      <h3>{item.CompanyName}</h3>
                      <p className="info">
                        {item.specialization}
                        <span>&bull;</span>{" "}
                        <em className="date">
                          {item.MonthOfLeaving} {item.YearOfLeaving}
                        </em>
                      </p>
                      <p>{item.Achievements}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Skills</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <p>{resumeData.skillsDescription}</p>

            <div className="bars">
              <ul className="skills">
                {resumeData.skills &&
                  resumeData.skills.map((item) => {
                    return (
                      <li>
                        <span
                          className={`bar-expand ${item.skillname.toLowerCase()}`}
                        ></span>
                        <em>{item.skillname}</em>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div> */}
        </div>
      </section>
    );
  }
}
