import React, { Component } from "react";
export default class CenovaPonuka extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="cenovaponuka">
        <div className="row section-head">
          <div className="ten columns">
            <h2>Cenová ponuka</h2>
          </div>
        </div>
        <div className="row">
          <aside className="eigth columns footer-widgets">
            <div className="widget">
              <p>{resumeData.cenovaPonuka}</p>
              <span>
                Vyžiadať cenovú ponuku prostredníctvom emailu
                <a href="mailto:igorzacher90@gmail.com">
                  igorzacher90@gmail.com
                </a>
              </span>
            </div>
          </aside>
        </div>
      </section>
    );
  }
}
